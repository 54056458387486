.message{
    width: 100%;
    height: calc(100vh - 100px);
    border: 1px solid #ddd;
    margin-top: 15px;
    border-radius: 3px;
    background: #fbfbfb;
}



.message_header{
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
}

.message_header input{
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background: #f8f8f8;
    padding: 0 5px;
}

.message_chat_list{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
}

.message_chat_list .message_user{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #f3f3f3;
    color: #555;
    cursor: pointer;
}

.message_chat_list .message_user .fa-circle{
    font-size: 11px;
    color: #aaa;
}

.message_chat_list .message_user .fa-circle.active{
    color: var(--c1);
}
.message_chat_list .message_user.active{
    background: #eee;
}

.chat_input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-top: 1px solid #ddd;
}

.chat_input input{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
}

.chat_input button{
    border: none;
    outline: none;
    background: white;
}

.chat_input .file_upload{
    position: relative;
    overflow: hidden;
    margin: 0 10px;
}

.chat_input .file_upload #file{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.show_media{
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    place-items: center;
    grid-gap: 10px;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 0 5px;
}

.show_media #file_media{
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
}

.show_media #file_media img,
.show_media #file_media video{
        width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.show_media #file_media span{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background: white;
    border: solid 1px var(--c1);
    padding: 3px 7px;
    color: var(--c1);
    border-radius: 50%;
    font-size: 10px;
    cursor: pointer;
}

.chat_container{
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 0 10px;
}

.chat_display{
        width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat_row{
    display: grid;
    grid-template-columns: 70%;
    margin-bottom: 10px;
}
.you_message{
    justify-content: end;
    justify-items: end;
}
.other_message{
    justify-content: start;
    justify-items: start;
}
.chat_title{
    margin-bottom: 3px;
}

.chat_text{
    padding: 9px 14px;
    margin-bottom: 5px;
}

.chat_time{
    font-size: 13px;
    color: #777;
}

.you_message .chat_text{
    background: #0048aa;
    color: white;
    border: 1px solid #0048aa;
    border-radius: 14px;
    border-top-right-radius: 0;
}

.other_message .chat_text{
    background: white;
    color: #111;
    border: 1px solid #ddd;
    border-radius: 14px;
    border-top-left-radius: 0;
}
