.reactions{
    display: grid;
    grid-template-columns: repeat(4, 50px);
    text-align: center;
    cursor: pointer;
}

.reactions span{
    margin: 3px 0;
}
