 .loading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading svg{
    font-size: 5px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    animation: text 1s ease-in-out infinite;
}

@keyframes text{
    50%{
        opacity: 0.1;
    }
}

.loading polygon{
    stroke-dasharray: 22;
    stroke-dashoffset: 1;
    animation: dash 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate-reverse;
}

@keyframes dash{
    to{
        stroke-dashoffset: 234;
    }
} 




 .loading {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
    animation: animate 1.2s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loading span:nth-child(1) {
    filter: blur(5px);
}

.loading span:nth-child(2) {
    filter: blur(10px);
}

.loading span:nth-child(3) {
    filter: blur(25px);
}

.loading span:nth-child(4) {
    filter: blur(50px);
}

.loading:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #f1f1f1;
    border: solid white 10px;
    border-radius: 50%;
}

.loading_text{
    font-size: 2.6rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    animation: text 1s ease-in-out infinite;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    color: #0008;
}

@keyframes text{
    50%{
        opacity: 0.1;
    }
}